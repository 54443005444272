console.log('>> Common script is loaded.');
import Cookies from 'js-cookie';
const button = document.querySelector('#burger');
const menu = document.querySelector('#menu');
const body = document.body;
const memberButton = document.querySelector('.menu__member > button');
const joinUsMenu = document.querySelector('.menu__member__joinUs');
const closeButton = document.querySelector('#close-menu');

// back to top
document.getElementById("backToTopBtn").addEventListener("click", function() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' 
  });
});

// Проверка наличия элементов
// console.log('button:', button);
// console.log('menu:', menu);
// console.log('memberButton:', memberButton);
// console.log('joinUsMenu:', joinUsMenu);
// console.log('closeButton:', closeButton);

// Массив с путями к логотипам
const logos = [
  require('@images/icons/logo/logo_1.svg'), 
  require('@images/icons/logo/logo_2.svg'), 
  require('@images/icons/logo/logo_3.svg'), 
  require('@images/icons/logo/logo_4.svg'), 
  require('@images/icons/logo/logo_5.svg'),
  require('@images/icons/logo/logo_6.svg'),
  require('@images/icons/logo/logo_7.svg'),
  require('@images/icons/logo/logo_8.png')
];

// Функция для установки случайного логотипа
function setRandomLogo() {
  const logoImage = document.querySelector('.logo img');
  if (logoImage) {
    const randomIndex = Math.floor(Math.random() * logos.length);
    logoImage.src = logos[randomIndex];
  }
}


// Устанавливаем случайный логотип при загрузке страницы
window.addEventListener('DOMContentLoaded', setRandomLogo);

// Меняем логотип при каждом переходе по внутренним ссылкам
document.querySelectorAll('a').forEach(link => {
  link.addEventListener('click', () => {
    setRandomLogo();
  });
});

if (button && menu) {
  button.addEventListener('click', () => {
    button.classList.toggle('-menu-open');
    menu.classList.toggle('__open');

    if (menu.classList.contains('__open')) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
      if (joinUsMenu) {
        joinUsMenu.classList.remove('__open'); // Закрываем joinUsMenu, если основное меню закрывается
      }
    }
  });
}

if (memberButton && joinUsMenu) {
  memberButton.addEventListener('click', (event) => {
    event.stopPropagation();
    joinUsMenu.classList.toggle('__open');
  });
}

if (closeButton) {
  closeButton.addEventListener('click', () => {
    if (joinUsMenu && joinUsMenu.classList.contains('__open')) {
      joinUsMenu.classList.remove('__open'); // Сначала закрываем joinUsMenu
    } else {
      button.classList.remove('-menu-open');
      menu.classList.remove('__open'); // Затем закрываем основное меню
      body.classList.remove('no-scroll');
    }
  });
}

const joinUsLinks = document.querySelectorAll('.menu__member__joinUs__link');
if (joinUsLinks.length > 0) {
  joinUsLinks.forEach(link => {
    link.addEventListener('click', () => {
      if (joinUsMenu) {
        joinUsMenu.classList.remove('__open'); // Закрываем joinUsMenu при клике на ссылку
      }
    });
  });
}


//cookiesBanner
const googleId = 'GTM-M2W2RPVK';
document.addEventListener('DOMContentLoaded', function () {
  const cookieBanner = document.getElementById('cookie-banner');
  const acceptCookiesButton = document.getElementById('accept-cookies');
  const declineCookiesButton = document.getElementById('decline-cookies');

  // Проверка, приняты ли куки
  const hasAcceptedCookies = Cookies.get('cookies-accepted');

  // Если куки не приняты, показать баннер
  if (!hasAcceptedCookies) {
    cookieBanner.style.display = 'block';
  }

  // При нажатии на "Accept" записать куки и скрыть баннер
  acceptCookiesButton.addEventListener('click', function () {
    Cookies.set('cookies-accepted', 'true', { expires: 30 }); // Куки действительны 30 дней
    Cookies.set('google-id', googleId, { expires: 30 }); // Установка Google ID
    cookieBanner.style.display = 'none'; // Скрываем баннер
  });

  // При нажатии на "Decline" просто скрыть баннер, но не записывать куки
  declineCookiesButton.addEventListener('click', function () {
    cookieBanner.style.display = 'none'; // Скрываем баннер, но не записываем ничего
  });
});
